import { LoadingSpinner } from "components/LoadingSpinner";
import { mapPayerNameToCodeForDropdown } from "constants/practiceType";
import useFormValidation from "hooks/useFormValidation";
import { usePayerInformation } from "hooks/usePayerInformation";
import { useQuery } from "hooks/useQuery";
import { sizer } from "layout/styles/styled/sizer";
import { Label } from "layout/typography/Label";
import { isEmpty } from "lodash";
import { handleChangeDateWithMask } from "pages/CreateOrder/utils/mask";
import { Card } from "primereact/card";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { useEffect, useMemo, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { useGetAccountByIdQuery } from "store/queries/account";
import styled from "styled-components";
import { IGFEServiceDetails } from "types/Order/Order";

import { EligibilityButton } from "./components/EligibilityButton";
import { Message } from "./components/Message";

const StyledCard = styled(Card)<{ ordertypefield: string }>`
  margin-bottom: ${sizer(4)};
  display: ${({ ordertypefield }) =>
    ordertypefield === "GFE" ? "block" : "none"};
`;

export function PayerForm({
  redo = false,
  draft = false,
}: {
  redo?: boolean;
  draft?: boolean;
}) {
  const {
    control,
    trigger,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();
  const query = useQuery();
  const accountId = query.get("accountId") || "";
  const [eligibilityMessage, setEligibilityMessage] = useState<string>("");
  const [showMemberIdAndEligibility, setShowMemberIdAndEligibility] =
    useState<boolean>(true);

  const { selectedDraftOrder: draftOrder } = useSelector(
    (state: RootState) => state.orderSlice
  );
  const { selectedOrderToRedo: orderToRedo } = useSelector(
    (state: RootState) => state.orderSlice
  );

  const { data: accountData } = useGetAccountByIdQuery(accountId, {
    skip: !accountId,
  });

  const { handleOnBlurField, getFormErrorMessage } = useFormValidation();

  const orderTypeField = watch("orderType");
  const payerNameField = watch("payerName");
  const isSubscriberPatientCheckbox = watch("isSubscriberPatient");

  const { payerNameOptions, loadingPayersName } = usePayerInformation();

  const mainProvider = useMemo(() => {
    return (
      accountData?.data?.providers.find(
        (aMainProvider) => !!aMainProvider.mainFacility
      ) || {}
    );
  }, [accountData]);

  // FIX ME: BUGGY BUT YET NEEDED CODE TO DEFAULT VALUE FOR FETCHED PAYER NAME
  // SHOULD FIND OUT HOW TO SET THE DEFAULT VALUE FOR A DROPDOWN IN A REACT FORM
  // THE VALUE WILL BE SET AFTER IT FINISHES LOADING DATA.

  useEffect(() => {
    if (isEmpty(payerNameField) && !isEmpty(payerNameOptions)) {
      if (redo) {
        setValue(
          "payerName",
          (orderToRedo?.serviceDetails as IGFEServiceDetails)?.payerName ||
            payerNameOptions[0].value
        );
      } else {
        setValue(
          "payerName",
          (draftOrder?.serviceDetails as IGFEServiceDetails)?.payerName ||
            payerNameOptions[0].value
        );
      }
    }
  }, [payerNameOptions, payerNameField, redo]);

  useEffect(() => {
    if (payerNameField === "Self-Pay Rate") {
      setShowMemberIdAndEligibility(false);
      setValue("deductible", 0);
      setValue("outOfPocketMax", 100000);
      setValue("flatCopay", 0);
      setValue("coinsurancePercent", 100);
    } else {
      setShowMemberIdAndEligibility(true);
      if (!redo && !draft) {
        setValue("deductible", null);
        setValue("outOfPocketMax", null);
        setValue("flatCopay", null);
        setValue("coinsurancePercent", null);
      }
    }
  }, [payerNameField]);

  return (
    <>
      <StyledCard title="Payer" ordertypefield={orderTypeField}>
        <div className="flex flex-column gap-4 w-100">
          <div className="formgrid grid w-100">
            <div className="field flex flex-column col-12 md:col-12 lg:col-3">
              <Label htmlFor="payerName">Payer Name</Label>

              <Controller
                control={control}
                name="payerName"
                render={({ field: { onChange, value } }) =>
                  loadingPayersName ? (
                    <LoadingSpinner
                      customStyle={{ width: "50px", height: "100px" }}
                    />
                  ) : (
                    <Dropdown
                      value={value || ""}
                      optionLabel="name"
                      placeholder="Any"
                      id="payerName"
                      data-testid="payerName_PayerForm"
                      options={payerNameOptions}
                      onChange={(e) => {
                        onChange(e.value);
                        if (redo) {
                          setValue("redoOrderFirstRender", false);
                        }
                        if (draft) {
                          setValue("draftOrderFirstRender", false);
                        }
                      }}
                      showClear
                    />
                  )
                }
              />
            </div>

            {!!mainProvider.eligibilityEnabled && (
              <div className="field flex flex-column col-12 md:col-12 lg:col-3">
                <Label htmlFor="practiceType">Service Type</Label>

                <Controller
                  control={control}
                  name="practiceType"
                  render={({ field: { onChange, value } }) => (
                    <Dropdown
                      value={value || ""}
                      optionLabel="name"
                      data-testid="practiceType_PayerForm"
                      placeholder="Service Type"
                      id="practiceType"
                      options={mapPayerNameToCodeForDropdown}
                      onChange={onChange}
                      showClear
                    />
                  )}
                />
              </div>
            )}
          </div>
          {!!mainProvider.eligibilityEnabled && (
            <>
              <div className="formgrid grid w-100">
                {!isSubscriberPatientCheckbox && (
                  <>
                    <div className="field flex flex-column col-12 md:col-12 lg:col-3">
                      <Label htmlFor="subscriberFirstName">
                        Subscriber First Name
                      </Label>

                      <Controller
                        control={control}
                        name="subscriberFirstName"
                        render={({ field: { onChange, value, onBlur } }) => (
                          <>
                            <InputText
                              id="subscriberFirstName"
                              data-testid="subscriberFirstName_PayerForm"
                              value={value || ""}
                              autoComplete="off"
                              placeholder="Subscriber Name"
                              onBlur={() =>
                                handleOnBlurField({
                                  onBlur,
                                  field: "subscriberFirstName",
                                  trigger,
                                })
                              }
                              onChange={onChange}
                            />

                            {getFormErrorMessage("subscriberFirstName", errors)}
                          </>
                        )}
                      />
                    </div>
                    <div className="field flex flex-column col-12 md:col-12 lg:col-3">
                      <Label htmlFor="subscriberLastName">
                        Subscriber Last Name
                      </Label>

                      <Controller
                        control={control}
                        name="subscriberLastName"
                        render={({ field: { onChange, value, onBlur } }) => (
                          <>
                            <InputText
                              id="subscriberLastName"
                              data-testid="subscriberLastName_PayerForm"
                              value={value || ""}
                              autoComplete="off"
                              placeholder="Subscriber Name"
                              onBlur={() =>
                                handleOnBlurField({
                                  onBlur,
                                  field: "subscriberLastName",
                                  trigger,
                                })
                              }
                              onChange={onChange}
                            />

                            {getFormErrorMessage("subscriberLastName", errors)}
                          </>
                        )}
                      />
                    </div>
                    <div className="field flex flex-column col-12 md:col-12 lg:col-3">
                      <Label htmlFor="subscriberDOB">Subscriber DOB</Label>

                      <Controller
                        name="subscriberDOB"
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <>
                            <InputText
                              value={value || ""}
                              id="subscriberDOB"
                              data-testid="subscriberDOB_PayerForm"
                              placeholder="99/99/9999"
                              onBlur={() =>
                                handleOnBlurField({
                                  onBlur,
                                  field: "subscriberDOB",
                                  trigger,
                                })
                              }
                              onChange={(e) =>
                                handleChangeDateWithMask(e, onChange)
                              }
                            />

                            {getFormErrorMessage("subscriberDOB", errors)}
                          </>
                        )}
                      />
                    </div>
                  </>
                )}
                {showMemberIdAndEligibility ? (
                  <div className="field flex flex-column col-12 md:col-12 lg:col-3">
                    <Label htmlFor="memberId">Member ID</Label>

                    <Controller
                      control={control}
                      name="memberId"
                      render={({ field: { onChange, value, onBlur } }) => (
                        <>
                          <InputText
                            id="memberId"
                            value={value || ""}
                            autoComplete="off"
                            data-testid="memberId_PayerForm"
                            placeholder="Member ID"
                            onBlur={() =>
                              handleOnBlurField({
                                onBlur,
                                field: "memberId",
                                trigger,
                              })
                            }
                            onChange={onChange}
                          />

                          {getFormErrorMessage("memberId", errors)}
                        </>
                      )}
                    />
                  </div>
                ) : null}
                <div className="field flex-column col-12 md:col-12 lg:col-3 hidden">
                  <Label htmlFor="groupNumber">Group Number</Label>

                  <Controller
                    control={control}
                    name="groupNumber"
                    render={({ field: { onChange, value, onBlur } }) => (
                      <>
                        <InputText
                          id="groupNumber"
                          data-testid="groupNumber_PayerForm"
                          value={value || ""}
                          autoComplete="off"
                          placeholder="Group Number"
                          onBlur={() =>
                            handleOnBlurField({
                              onBlur,
                              field: "groupNumber",
                              trigger,
                            })
                          }
                          onChange={onChange}
                        />

                        {getFormErrorMessage("groupNumber", errors)}
                      </>
                    )}
                  />
                </div>
              </div>
              {showMemberIdAndEligibility ? (
                <EligibilityButton
                  mainProvider={mainProvider}
                  errorMessageSetter={setEligibilityMessage}
                />
              ) : null}
            </>
          )}
        </div>
      </StyledCard>
      <Message eligibilityMessage={eligibilityMessage} />
    </>
  );
}
