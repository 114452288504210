import { LoadingSpinner } from "components/LoadingSpinner";
import {
  orderTypeConstants,
  ED_ORDER_TYPE_EXPIRATION_PERIOD,
} from "constants/order";
import { centsToDollars } from "helpers/price";
import usePrice from "hooks/usePrice";
import { isEmpty } from "lodash";
import { DEFAULT_EXPIRATION_DAYS } from "pages/OrderOverview/utils/constants";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams, Navigate } from "react-router-dom";
import { useLoginWithOrderIdAndDOBMutation } from "store/queries/auth";
import {
  useGetOrderOverviewQuery,
  useGetPublicOrderQuery,
} from "store/queries/order";
import { onPublicTokenChange } from "store/slices/auth";
import { onChangeAccountInfo } from "store/slices/order";
import { IOrder } from "types/Order/Order";
import { IProvider } from "types/Provider/Provider";

import { PayNowSection } from "../PayNowSection";

export function PayNow() {
  const { id: externalId } = useParams();

  const dispatch = useDispatch();

  const { getClearPriceByOrderType } = usePrice();
  const [login] = useLoginWithOrderIdAndDOBMutation();

  const [isPublicTokenReady, setIsPublicTokenReady] = useState(false);

  // get the order overview for patient date of birth
  const {
    data: overviewData,
    isLoading: getOrderOverviewIsLoading,
    isError: getOrderOverviewError,
  } = useGetOrderOverviewQuery(externalId, { skip: !externalId });

  const {
    data: orderData,
    isLoading: getPublicOrderIsLoading,
    isError: getPublicOrderError,
  } = useGetPublicOrderQuery(externalId, {
    skip: !isPublicTokenReady,
  });

  const orderInfo = useMemo(() => {
    if (isEmpty(orderData)) return {} as IOrder;

    const accountInfo = orderData.data.account;

    if (accountInfo) {
      dispatch(onChangeAccountInfo({ accountInfo }));
    }

    return orderData.data;
  }, [orderData]);

  useEffect(() => {
    if (isEmpty(overviewData)) return;
    login({
      orderId: externalId || "",
      dateOfBirth: overviewData.data.patient.dob,
    })
      .unwrap()
      .then(({ token }: { token: string }) => {
        dispatch(onPublicTokenChange({ token }));
        setIsPublicTokenReady(true);
      })
      .catch((error) => {
        setIsPublicTokenReady(false);
        console.error(
          `Authentication failed for order ${externalId} with date of birth ${overviewData.data.patient.dob}.`,
          error
        );
      });
  }, [overviewData]);

  // We can't use the useLoading hook because the clientSecret is required to load the payment for now.
  if (
    !isPublicTokenReady ||
    getPublicOrderIsLoading ||
    getOrderOverviewIsLoading
  ) {
    return <LoadingSpinner />;
  }

  if (getPublicOrderError || getOrderOverviewError) {
    return <Navigate to="/not-found" />;
  }

  const prices = getClearPriceByOrderType({ order: orderInfo });
  const activePaymentPlan = orderInfo.paymentPlan?.find(
    (paymentPlan) => !!paymentPlan.active
  );

  const fullPrice = centsToDollars(prices.total);
  const remainingPrice = centsToDollars(
    activePaymentPlan?.remainingAmountInCents || 0
  );
  const totalSaving = centsToDollars(prices.totalSaving);

  const getExpirationDays = () => {
    if (isEmpty(orderData)) return undefined;

    if (getPublicOrderIsLoading) return DEFAULT_EXPIRATION_DAYS;

    if (orderInfo.orderType === orderTypeConstants.ED) {
      return ED_ORDER_TYPE_EXPIRATION_PERIOD;
    }

    return (orderInfo.account.providers[0] as unknown as IProvider)
      .expirationDays;
  };

  const expirationDays = getExpirationDays();

  return (
    <PayNowSection
      {...{
        expirationDays,
        orderInfo,
        showPaymentSection: {
          paymentPlan: false,
          paymentSection: false,
        },
        setShowPaymentSection: undefined,
        clearPrice: remainingPrice,
        totalSaving,
        fullPrice,
        payRemainingPrice: true,
      }}
    />
  );
}
