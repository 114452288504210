import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import styled from "styled-components";

const AddNoteContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 330px;
`;

export function AddNoteDialog({
  showDialog,
  setShowDialog,
  note,
  setNote,
  onAddNote,
}: {
  showDialog: boolean;
  setShowDialog: any;
  note: string;
  setNote: any;
  onAddNote: any;
}) {
  const footerComponent = () => {
    return (
      <div>
        <Button
          label="Cancel"
          onClick={() => {
            setNote("");
            setShowDialog(false);
          }}
          className="p-button-link mr-2"
        />
        <Button
          label="Add"
          onClick={() => {
            onAddNote();
          }}
          disabled={!note.trim().length}
        />
      </div>
    );
  };
  return (
    <Dialog
      onHide={() => {
        setNote("");
        setShowDialog(false);
      }}
      visible={showDialog}
      header="Add Note"
      footer={footerComponent}
    >
      <AddNoteContainer>
        <span>Please, add your note here!</span>
        <InputTextarea
          value={note}
          onChange={(e) => setNote(e.target.value)}
          rows={2}
          placeholder="Notes"
          className="w-full mt-3"
        />
      </AddNoteContainer>
    </Dialog>
  );
}
