import { validationConstants } from "constants/formValidation";
import { Label } from "layout/typography/Label";
import { Dropdown } from "primereact/dropdown";
import { useMemo, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { IOrderTypesVisible } from "types/Account/Account";

import { StyledCard } from "../../CreateOrder.styled";
import { orderTypes } from "../Header/constants";

export function OrderType({
  orderTypesVisible,
  isFetching,
  redo = false,
}: {
  orderTypesVisible?: IOrderTypesVisible;
  isFetching?: boolean;
  redo?: boolean;
}) {
  const { control, setValue } = useFormContext();

  const orderTypesDropdown = useMemo(() => {
    if (isFetching) {
      return [];
    }
    let orderTypeValues = Object.values(orderTypes);
    if (orderTypesVisible) {
      orderTypeValues = orderTypeValues.filter((orderType) => {
        const key =
          orderType.value === "PatientResponsibility"
            ? "patientResponsibility"
            : orderType.value.toLowerCase();
        return orderTypesVisible[key] === true;
      });
    }
    return orderTypeValues;
  }, [orderTypes, orderTypesVisible]);

  useEffect(() => {
    if (orderTypesDropdown.length === 1) {
      setValue("orderType", orderTypesDropdown[0].value);
    }
  }, [orderTypesDropdown]);

  return (
    <StyledCard data-testid="orderTypeTitle" title="Order Type">
      <div className="flex flex-column gap-4 w-100">
        <div className="formgrid grid w-100">
          <div className="field flex flex-column col-12">
            <Label htmlFor="orderType" data-testid="orderTypeLabel">
              Order Type
            </Label>

            <Controller
              data-testid="orderType"
              name="orderType"
              control={control}
              rules={{
                required: validationConstants.VISIT_INFO_FORM.DATE_OF_SERVICE,
              }}
              render={({ field: { onChange, value } }) => (
                <Dropdown
                  id="orderType"
                  data-testid="orderType"
                  value={value}
                  onChange={onChange}
                  options={orderTypesDropdown}
                  optionLabel="name"
                  placeholder="Select order type"
                  className="w-full"
                  disabled={isFetching || redo}
                  dropdownIcon={
                    isFetching ? "pi pi-spin pi-spinner" : "pi pi-chevron-down"
                  }
                />
              )}
            />
          </div>
        </div>
      </div>
    </StyledCard>
  );
}
