import { filterUniqueObjects } from "helpers/object";
import { isEmpty, maxBy } from "lodash";
import { AutoCompleteSelectParams } from "primereact/autocomplete";
import {
  setAutoCompleteValue,
  setRows,
  setSelectedProcedures,
  updateRows,
  addSelectedProcedures,
  addAutoCompleteValue,
  removeRows,
} from "store/slices/serviceDetails";
import { ProcedureRow } from "types/CreateOrder";
import { IProcedure } from "types/Procedure";

import {
  buildSelectedProceduresObject,
  buildSelectedProceduresToTable,
} from "./builders";
import { getPreviousQuantity } from "./helpers";
import { ISelectedProcedure } from "./types";

export const autoCompleteFieldHandleOnSelectProcedure = async ({
  e,
  dispatch,
  selectedProcedures,
  procedures,
  providerProcedures,
  rows,
  newValue,
  row,
}: {
  e: AutoCompleteSelectParams;
  dispatch: any;
  selectedProcedures: string[];
  procedures: IProcedure[];
  providerProcedures: any;
  rows: ProcedureRow[];
  newValue: number;
  row?: ProcedureRow;
}) => {
  const theSelectedProcedures: string[] = [...selectedProcedures];
  const previousQuantity = getPreviousQuantity(rows, e.value);

  if (newValue < previousQuantity) {
    if (isEmpty(row)) return;

    const theProcedureRowToDelete: ProcedureRow | undefined = maxBy(
      rows.filter((aRow) => aRow.cptCode === row.cptCode),
      "internalId"
    );

    const rowsToRemove: ProcedureRow[] = rows.filter(
      (aRow) =>
        aRow.internalId === theProcedureRowToDelete?.internalId &&
        aRow.cptCode === theProcedureRowToDelete?.cptCode
    );

    const idsToRemove = rowsToRemove.map((row) => row.id.toString());

    const remainingRows: ProcedureRow[] = rows.filter(
      (aRow) => aRow.internalId !== theProcedureRowToDelete?.internalId
    );

    const newSelectedProcedures = remainingRows.map(
      (row) => `${row.cptCode} - ${row.description}`
    );

    dispatch(removeRows(idsToRemove));
    dispatch(setSelectedProcedures(newSelectedProcedures));
  } else if (newValue > previousQuantity) {
    const selectedProceduresObject = buildSelectedProceduresObject([
      ...theSelectedProcedures,
      e.value,
    ]);

    const rowsToAdd: ProcedureRow[] = buildSelectedProceduresToTable({
      selectedProceduresObject,
      procedures,
      providerProcedures,
      rows,
      row,
    });

    dispatch(updateRows(rowsToAdd));
    dispatch(addSelectedProcedures(e.value));
    dispatch(addAutoCompleteValue(e.value));
  }
};

export const autoCompleteHandleOnUnSelectProcedure = ({
  e,
  autoCompleteValue,
  rows,
  dispatch,
}: {
  e: AutoCompleteSelectParams;
  autoCompleteValue: string[];
  rows: ProcedureRow[];
  dispatch: any;
}) => {
  const uniqueProviderProceduresRow = filterUniqueObjects(rows, ["internalId"]);

  const procedureIndex = autoCompleteValue.findIndex(
    (currentProcedure) => currentProcedure === e.value
  );

  const procedureElement = uniqueProviderProceduresRow[procedureIndex];
  if (procedureElement && procedureIndex !== -1) {
    const filteredProcedures = rows.filter(
      (row) => row.cptCode !== procedureElement.cptCode
    );

    const newAutoComplete = autoCompleteValue.filter(
      (currentSelectedProcedure) => currentSelectedProcedure !== e.value
    );

    const newSelectedProcedures = filteredProcedures.map(
      (row) => `${row.cptCode} - ${row.description}`
    );

    dispatch(setRows(filteredProcedures));
    dispatch(setAutoCompleteValue(newAutoComplete));
    dispatch(setSelectedProcedures(newSelectedProcedures));
  }
};

export const autoCompleteHandleRemoveProcedure = ({
  selectedRow,
  rows,
  selectedProceduresObject,
  dispatch,
}: {
  selectedRow: ProcedureRow;
  rows: ProcedureRow[];
  selectedProceduresObject: ISelectedProcedure[];
  dispatch: any;
}) => {
  const uniqueProceduresSet = new Set();

  const newRows = rows
    ?.map((currentRow) => {
      if (currentRow.providerProcedureId !== selectedRow.providerProcedureId) {
        return { ...currentRow, quantity: 1 };
      }
      return undefined;
    })
    .filter(Boolean) as ProcedureRow[];

  const updateAutoCompleteProcedureList = selectedProceduresObject
    .filter((procedure) => {
      const matchRow = newRows.find((row) => row.cptCode === procedure.code);
      if (!matchRow) {
        return false;
      }
      if (uniqueProceduresSet.has(procedure.cptDescription)) {
        return false;
      }
      uniqueProceduresSet.add(procedure.cptDescription);
      return true;
    })
    .map((procedure) => `${procedure.code} - ${procedure.cptDescription}`);

  const procedureList = !isEmpty(newRows)
    ? updateAutoCompleteProcedureList
    : [];

  const uniqueProviderProceduresRow = filterUniqueObjects(newRows, [
    "internalId",
  ]);
  const newSelectedProcedures = uniqueProviderProceduresRow.map(
    (row) => `${row.cptCode} - ${row.description}`
  );

  dispatch(setAutoCompleteValue(procedureList));
  dispatch(setSelectedProcedures(newSelectedProcedures));

  dispatch(setRows(newRows));
};

export const quantityChangeHandleOnSelectProcedure = async ({
  e,
  dispatch,
  selectedProcedures,
  procedures,
  providerProcedures,
  rows,
  newValue,
  row,
}: {
  e: AutoCompleteSelectParams;
  dispatch: any;
  selectedProcedures: string[];
  procedures: IProcedure[];
  providerProcedures: any;
  rows: ProcedureRow[];
  newValue: number;
  row?: ProcedureRow;
}) => {
  const theSelectedProcedures: string[] = [...selectedProcedures];
  const previousQuantity = getPreviousQuantity(rows, e.value);
  const existingRows = [...rows];

  if (newValue < previousQuantity) {
    if (isEmpty(row)) return;

    let itemsToRemoveCount = previousQuantity - newValue;

    while (itemsToRemoveCount > 0) {
      const theProcedureRowToDelete: ProcedureRow | undefined = maxBy(
        rows.filter((aRow) => aRow.cptCode === row.cptCode),
        "internalId"
      );

      if (!theProcedureRowToDelete) break;

      const rowToRemove: ProcedureRow[] = rows.filter(
        (aRow) =>
          aRow.internalId === theProcedureRowToDelete?.internalId &&
          aRow.cptCode === theProcedureRowToDelete?.cptCode
      );

      const idToRemove = rowToRemove.map((row) => row.id.toString());

      rows = rows.filter(
        (aRow) => aRow.internalId !== theProcedureRowToDelete?.internalId
      );

      itemsToRemoveCount -= 1;

      dispatch(removeRows(idToRemove));
    }

    const newSelectedProcedures = rows.map(
      (row) => `${row.cptCode} - ${row.description}`
    );

    dispatch(setSelectedProcedures(newSelectedProcedures));
  } else if (newValue > previousQuantity) {
    let itemsToAddCount = newValue - previousQuantity;

    while (itemsToAddCount > 0) {
      const selectedProceduresObject = buildSelectedProceduresObject([
        ...theSelectedProcedures,
        e.value,
      ]);

      const filteredRows = row
        ? existingRows.filter((aRow) => aRow.cptCode === row.cptCode)
        : [];

      const theRowToUseInBuild: ProcedureRow | undefined = row
        ? maxBy(filteredRows, "internalId")
        : undefined;

      const rowToAdd: ProcedureRow[] = buildSelectedProceduresToTable({
        selectedProceduresObject,
        procedures,
        providerProcedures,
        rows: existingRows,
        row: theRowToUseInBuild,
      });

      itemsToAddCount -= 1;
      existingRows.push(...rowToAdd);
      dispatch(updateRows(rowToAdd));
      dispatch(addSelectedProcedures(e.value));
    }
    dispatch(addAutoCompleteValue(e.value));
  }
};
