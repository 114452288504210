import { orderTypeConstants } from "constants/order";
import { mapPayerNameToCodeForDropdown } from "constants/practiceType";
import dayjs from "dayjs";
import { centsToDollars } from "helpers/price";
import { usePayerInformation } from "hooks/usePayerInformation";
import { CLEAR_HEALTH_PAYER_NAME } from "hooks/useProviderProcedure";
import { useMemo, useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useSearchProviderProcedureMutation } from "store/queries/providerProcedure";
import {
  setAutoCompleteValue,
  setRows,
  setSelectedProcedures,
} from "store/slices/serviceDetails";
import {
  IOrder,
  IGFEServiceDetails,
  IDefaultServiceDetails,
} from "types/Order/Order";

import {
  VisitForm,
  Submit,
  PatientInfoForm,
  OrderType,
  ServiceDetails,
  PayerForm,
  PatientBenefitsForm,
} from "./components";
import { PaymentPlan } from "./components/PaymentPlan/PaymentPlan";
import { RedoHeader } from "./components/RedoHeader";
import {
  buildSelectedProceduresArray,
  buildSelectedProceduresObject,
  buildSelectedProceduresToTable,
} from "./components/ServiceDetails/builders";
import { formatPhoneNumberToMask } from "./utils/mask";

export function RedoOrder({
  order,
  handleSwitchRedoOrder,
}: {
  order: IOrder;
  handleSwitchRedoOrder: () => void;
}) {
  const dispatch = useDispatch();
  const [searchMutation] = useSearchProviderProcedureMutation();
  const [currentProviderProcedures, setCurrentProviderProcedures] =
    useState<any>([]);

  const {
    serviceDetails,
    patient,
    procedures,
    providerProcedures,
    amountInCents,
    accountNumber,
    dateOfService,
    orderType,
    accountId,
    gfeQuote,
    gfeQuoteId,
    id,
    paymentPlan,
    account,
  } = order;

  const { payerNameOptions } = usePayerInformation(accountId);

  const estimatedVisitCost = useMemo(() => {
    if (orderType === orderTypeConstants.GFE) {
      return centsToDollars(
        (serviceDetails as IGFEServiceDetails)
          .patientResponsibilityEstimateAmountInCents || 0
      );
    }
    return centsToDollars(
      (serviceDetails as IDefaultServiceDetails).estimatedVisitCost || 0
    );
  }, [orderType, serviceDetails]);

  const payerName = useMemo(() => {
    if (orderType === orderTypeConstants.GFE) {
      return payerNameOptions.find(
        (aOption: { name: string; value: string }) =>
          aOption.name.toLowerCase() ===
          (serviceDetails as IGFEServiceDetails).payerName.toLowerCase()
      );
    }
    return null;
  }, [orderType, payerNameOptions]);

  const paymentPlanActive = useMemo(
    () => paymentPlan.find((paymentPlan) => paymentPlan.active),
    [paymentPlan]
  );

  const selectedProceduresList = useMemo(() => {
    return serviceDetails.procedures.map(
      (procedure) => `${procedure.code} - ${procedure.cptDescription}`
    );
  }, [serviceDetails]);

  const initializeDefault = async () => {
    const autoCompleteValue = [
      ...new Set(
        serviceDetails.procedures.map(
          (procedure) => `${procedure.code} - ${procedure.cptDescription}`
        )
      ),
    ];
    const selectedProcedureArray = buildSelectedProceduresArray({
      procedures: (serviceDetails as IGFEServiceDetails).procedures.map(
        (procedure) => {
          return {
            code: procedure.code,
            providerProcedureId: procedure.providerProcedureId,
            specialty: procedure.specialty,
            cptDescription: procedure.cptDescription,
          };
        }
      ),
    });
    const selectedProceduresObject = buildSelectedProceduresObject(
      selectedProcedureArray
    );
    const rowsToSet = buildSelectedProceduresToTable({
      selectedProceduresObject,
      procedures: procedures || [],
      providerProcedures: providerProcedures || [],
      rows: [],
    });
    const rowsWithoutDuplicateProviders = rowsToSet.map((row: any) => {
      const uniqueProviders = row.providerDropdown.filter(
        (provider: any, index: any, self: any) =>
          index === self.findIndex((p: any) => p.id === provider.id)
      );
      return {
        ...row,
        providerDropdown: uniqueProviders,
      };
    });

    const rowsWithCurrentAmount = rowsWithoutDuplicateProviders.map((row) => {
      const procedure = serviceDetails.procedures.find(
        (p) =>
          Number(p.code) === Number(row.cptCode) &&
          p.specialty === row.specialty &&
          p.providerId === row.selectedProvider.id &&
          p.providerProcedureId === row.providerProcedureId
      );
      return { ...row, amount: procedure?.amountInCents || row.amount };
    });

    const fetchedProviderProcedures: any[] = await Promise.all(
      selectedProceduresObject.map(async (procedure) => {
        const result = (await searchMutation({
          accountId,
          cptCode: procedure.code,
          payerName:
            orderType === orderTypeConstants.GFE
              ? serviceDetails.payerName
              : CLEAR_HEALTH_PAYER_NAME,
        })) as any;
        return result.data.data.data;
      })
    ).then((results) => results.flat());

    const rowsWithAllAvailableProviders = rowsWithCurrentAmount.map((row) => {
      const providerData = [...row.providerDropdown];
      fetchedProviderProcedures.forEach((providerProcedure) => {
        if (
          row.cptCode === providerProcedure.procedure.cptCode &&
          row.specialty === providerProcedure.provider.specialty &&
          row.procedureId === providerProcedure.procedureId &&
          !providerData.find((p) => p.id === providerProcedure.provider.id)
        ) {
          providerData.push({
            id: providerProcedure.provider.id,
            name: providerProcedure.provider.name,
            specialty: providerProcedure.provider.specialty,
            priceInCents: providerProcedure.priceInCents,
          });
        }
      });
      return {
        ...row,
        providerDropdown: providerData,
      };
    });

    dispatch(setAutoCompleteValue(autoCompleteValue));
    dispatch(setSelectedProcedures(selectedProcedureArray));
    dispatch(setRows(rowsWithAllAvailableProviders));
    setCurrentProviderProcedures(fetchedProviderProcedures);
  };

  useEffect(() => {
    if (orderType !== orderTypeConstants.PATIENT_RESPONSIBILITY) {
      initializeDefault();
    }
  }, []);

  const methods = useForm({
    defaultValues: {
      email: patient.email, // done
      amount: centsToDollars(
        (serviceDetails as IGFEServiceDetails)
          .patientResponsibilityEstimateAmountInCents || 0
      ),
      lastName: patient.lastName, // done
      firstName: patient.firstName, // done
      dateOfBirth: dayjs(patient.dateOfBirth).format("MM/DD/YYYY"), // done
      totalAmount: centsToDollars(amountInCents || 0),
      phoneNumber: formatPhoneNumberToMask(patient.phoneNumber), // done
      accountNumber,
      dateOfService: dayjs(dateOfService).format("MM/DD/YYYY"), // done
      timeOfService: dayjs(dateOfService).format("hh:mm A"), // done
      serviceDetails: serviceDetails.procedures,
      preferredContactMethod: patient.preferredContactMethod, // done
      estimatedVisitCostForPatient: estimatedVisitCost, // check not only for bundled but for other
      orderType, // done
      accountId, // done
      payerName: payerName?.name || null, // done
      deductible: centsToDollars(gfeQuote?.remainingDeductibleInCents || 0), // done
      outOfPocketMax: centsToDollars(gfeQuote?.outOfPocketMaxInCents || 0), // done
      flatCopay: centsToDollars(gfeQuote?.flatCopayInCents || 0), // done
      coinsurancePercent: gfeQuote?.coinsurancePercent || 0, // done
      gfeQuoteId,
      practiceType: mapPayerNameToCodeForDropdown[0]?.value, // check
      outOfPocketReason: serviceDetails?.outOfPocketReason
        ? { id: "", name: serviceDetails?.outOfPocketReason }
        : null, // done
      orderId: id,
      subscriberFirstName: patient.firstName, // done
      subscriberLastName: patient.lastName, // done
      subscriberDOB: dayjs(patient.dateOfBirth).format("MM/DD/YYYY"), // done
      memberId: patient.patientBenefits.memberID, // done
      isSubscriberPatient: patient.patientBenefits.isSubscriberPatient, // done
      paymentPlanMinDownPaymentFlat:
        paymentPlanActive?.paymentPlanMinDownPaymentFlat || null,
      paymentPlanEnabled: !!paymentPlanActive,
      redoOrderFirstRender: true,
    },
  });

  return (
    <FormProvider {...methods}>
      {account && (
        <RedoHeader
          accountName={account.name}
          handleSwitchRedoOrder={handleSwitchRedoOrder}
        />
      )}
      <OrderType
        orderTypesVisible={account?.orderTypesVisible}
        isFetching={false}
        redo
      />
      <PatientInfoForm />
      <PayerForm redo />
      <PatientBenefitsForm />
      <ServiceDetails
        redo
        selectedProceduresList={selectedProceduresList}
        totalAmount={order.amountInCents}
        currentProviderProcedures={currentProviderProcedures}
      />
      {!!account && (
        <PaymentPlan
          accountData={{ data: account }}
          paymentPlan={paymentPlanActive}
        />
      )}
      <VisitForm />
      <Submit redo />
    </FormProvider>
  );
}
