import { OrderHistoryType } from "types/OrderHistory";

export const orderHsitoryTypeConstants: { [key: string]: OrderHistoryType } = {
  Created: "Created",
  Updated: "Updated",
  Expired: "Expired",
  Canceled: "Canceled",
  Approved: "Approved",
  Paid: "Paid",
  Transfer: "Transfer",
  PartiallyPaid: "PartiallyPaid",
  InstallmentPaid: "InstallmentPaid",
  EmailSent: "EmailSent",
  TextMessageSent: "TextMessageSent",
  EstimateOpened: "EstimateOpened",
  NotesAdded: "NotesAdded",
  NoteToPdf: "NoteToPdf",
};
