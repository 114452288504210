import { orderTypeConstants } from "constants/order";
import { sizer } from "layout/styles/styled/sizer";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch } from "react-redux";
import { cleanUpServiceDetails } from "store/slices/serviceDetails";
import styled from "styled-components";

import { DefaultServiceDetails } from "./components/DefaultServiceDetails";
import { GFEServiceDetails } from "./components/GFEServiceDetails";
import { PatientResponsibilityServiceDetails } from "./components/PatientResponsibilityServiceDetails";

export const FieldsContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-top: ${sizer(4)};

  &:first-of-type {
    margin-right: 50px;
  }
`;

export function ServiceDetails({
  redo = false,
  selectedProceduresList,
  totalAmount,
  currentProviderProcedures,
  draft = false,
}: {
  redo?: boolean;
  draft?: boolean;
  selectedProceduresList?: string[];
  totalAmount?: number;
  currentProviderProcedures?: any;
}) {
  const { watch, setValue } = useFormContext();
  const orderTypeField = watch("orderType");
  const dispatch = useDispatch();
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    if ((redo && isFirstRender) || (draft && isFirstRender)) {
      setIsFirstRender(false);
      return;
    }
    setValue("amount", null);
    setValue("totalAmount", null);
    setValue("estimatedVisitCostForPatient", null);
    setValue("totalInsurancePortion", null);
    setValue("totalInsuranceAllowable", null);
    setValue("serviceDetails", []);

    dispatch(cleanUpServiceDetails());
    setIsFirstRender(false);
  }, [orderTypeField]);

  return (
    <>
      {(() => {
        switch (orderTypeField) {
          case orderTypeConstants.PATIENT_RESPONSIBILITY:
            return (
              <PatientResponsibilityServiceDetails
                selectedProceduresList={
                  isFirstRender && (redo || draft) ? selectedProceduresList : []
                }
                redo={redo}
                draft={draft}
                isFirstRender={isFirstRender}
              />
            );
          case orderTypeConstants.GFE:
            return (
              <GFEServiceDetails
                redo={redo}
                draft={draft}
                totalAmount={totalAmount}
                initialProviderProcedures={currentProviderProcedures}
              />
            );
          default:
            return (
              <DefaultServiceDetails
                initialProviderProcedures={currentProviderProcedures}
              />
            );
        }
      })()}
    </>
  );
}
