import {
  IOrderHistoryCreateRequest,
  ISingleOrderHistoryResponse,
} from "types/OrderHistory";

import { publicApi } from ".";

const publicOrderHistoryQueries = publicApi.injectEndpoints({
  endpoints: (builder) => ({
    createOrderHistory: builder.mutation<
      ISingleOrderHistoryResponse,
      IOrderHistoryCreateRequest
    >({
      query: (orderHistory) => ({
        body: {
          type: orderHistory.type,
          orderId: orderHistory.orderId,
          description: orderHistory.description,
          userId: orderHistory.userId || null,
          note: orderHistory.note || null,
        },
        method: "POST",
        url: "/order_histories",
      }),
    }),
  }),
});

export const { useCreateOrderHistoryMutation } = publicOrderHistoryQueries;
