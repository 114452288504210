import { ItemBar } from "components/ItemBar";
import { UserRole } from "constants/userRole";
import { ToastContext } from "context/ToastContext";
import { useUserWithAccount } from "context/UserAccountProvider";
import { useQuery } from "hooks/useQuery";
import { sizer } from "layout/styles/styled/sizer";
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";
import { useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCancelSingleOrderMutation } from "store/queries/order";
import { userSelector } from "store/slices/user";
import styled from "styled-components";

const StyledH2 = styled.h2`
  margin-bottom: ${sizer(8)};
`;

export const StyledButton = styled(Button)`
  gap: ${sizer(2)};
  margin-bottom: -30px;

  span:first-child {
    font-size: ${sizer(3)};
  }
`;

export function DraftHeader({ orderId }: { orderId: string }) {
  const { current: toastElement } = useContext(ToastContext);
  const navigate = useNavigate();
  const query = useQuery();
  const accountId = query.get("accountId") || undefined;
  const { accounts } = useUserWithAccount();
  const { scope } = useSelector(userSelector);
  const [cancelSingleOrder] = useCancelSingleOrderMutation();

  const accountName = useMemo(() => {
    if (!accounts || !accountId) return "";
    const account = accounts.data.find((acc) => acc.id === accountId);
    return account?.name || "";
  }, [accounts]);

  const cancelOrder = () => {
    cancelSingleOrder({ orderId })
      .unwrap()
      .then(() => {
        navigate(`/hospitals/view/${accountId}?tab=0`);
        toastElement?.show({
          severity: "success",
          summary: "Order canceled",
          detail: "Order canceled successfully.",
        });
      })
      .catch((error) =>
        toastElement?.show({
          severity: "error",
          summary: "Error",
          detail: `Message: ${error}`,
        })
      );
  };

  const handleOnClickToCancelOrder = () => {
    confirmDialog({
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      message: `Are you sure you want to cancel this order?`,
      accept: cancelOrder,
    });
  };

  return (
    <ItemBar>
      <StyledH2>{accountName}</StyledH2>

      <div data-testid="header" className="flex flex-1 justify-content-end">
        {scope === UserRole.SUPER_ADMIN && (
          <StyledButton
            data-testid="cancel-order_OrderHeader"
            icon="pi pi-file"
            className="p-button-text p-button-primary p-0 mb-0"
            onClick={handleOnClickToCancelOrder}
          >
            <p className="small-text">Cancel Order</p>
          </StyledButton>
        )}
      </div>
    </ItemBar>
  );
}
