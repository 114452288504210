import { LoadingSpinner } from "components/LoadingSpinner";
import { useGetOrdersQuery } from "store/queries/order";

import { SalesValues } from "./components/SalesValues";
import { SalesVolume } from "./components/SalesVolume";
import { TicketCharts } from "./components/TicketCharts/TicketCharts";

export function Dashboard({ accountId }: { accountId?: string }) {
  const { data: orders, isLoading } = useGetOrdersQuery(accountId);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="formgrid grid col-2 gap-2 flex-wrap w-full">
      {orders?.data && <TicketCharts orders={orders.data} />}
      {orders?.data && <SalesVolume orders={orders.data} />}
      {orders?.data && <SalesValues orders={orders.data} />}
    </div>
  );
}
