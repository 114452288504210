import { DownTrend } from "assets/icons/DownTrend";
import { UpTrend } from "assets/icons/UpTrend";
import { Tooltip } from "primereact/tooltip";
import { useMemo } from "react";
import styled from "styled-components";

const StyledContainer = styled.div`
  width: 300px;
  background-color: #fff;
  border-radius: 14px;
  box-shadow: 6px 6px 54px 0px rgba(0, 0, 0, 0.05);
  border: 1px solid #dee2e6;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const StyledIconContainer = styled.div<{ color?: string }>`
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.color || "#F9F9FB"};
  border-radius: 23px;
`;

const StyledTrendPercentage = styled.p<{ trend: "up" | "down" }>`
  color: ${(props) => (props.trend === "up" ? "#00B69B" : "#FF5C58")};
  font-size: 15px;
`;

const timing = {
  daily: "yesterday",
  weekly: "past week",
};

export function Ticket({
  icon,
  color,
  title,
  result,
  percentage = false,
  currency = false,
  tooltipText,
}: {
  icon: any;
  color: string;
  title: string;
  result: number;
  percentage?: boolean;
  currency?: boolean;
  tooltipText?: string;
}) {
  const formattedResult = useMemo(() => {
    return new Intl.NumberFormat("en-US").format(result);
  }, [result]);

  return (
    <StyledContainer>
      <div className="flex gap-2 justify-content-between">
        <div className="flex flex-column gap-3">
          <div className="flex gap-2">
            <p className="font-semibold text-sm">{title}</p>
            {tooltipText && (
              <Tooltip
                target=".info-icon"
                style={{
                  fontSize: "14px",
                  width: "250px",
                }}
              />
            )}
            <i
              className="pi pi-info-circle info-icon"
              data-pr-tooltip={tooltipText}
              style={{ color: "var(--primary-400)" }}
            />
          </div>
          {!Number.isNaN(result) ? (
            <p className="font-semibold text-2xl">
              {currency ? "$" : ""}
              {formattedResult}
              {percentage ? "%" : ""}
            </p>
          ) : (
            <p className="font-semibold text-2xl">N/A</p>
          )}
        </div>
        <StyledIconContainer color={color}>{icon}</StyledIconContainer>
      </div>
    </StyledContainer>
  );
}
