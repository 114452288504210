import { isEmpty } from "lodash";
import { useMemo, useState } from "react";
import { useSearchProviderProcedureMutation } from "store/queries/providerProcedure";
import { IProviderProcedure } from "types/ProviderProcedure";

import { useQuery } from "./useQuery";

export const CLEAR_HEALTH_PAYER_NAME = "Clear Health";

export function useProviderProcedure({
  payerNameField,
}: {
  payerNameField?: string;
}) {
  const query = useQuery();
  const accountId = query.get("accountId") || "";
  const [filteredProviderProceduresData, setFilteredProviderProceduresData] =
    useState<IProviderProcedure[]>();

  const [searchMutation, { isLoading }] = useSearchProviderProcedureMutation();

  const fetchFilteredProviderProceduresData = async (
    searchInput: string
  ): Promise<IProviderProcedure[] | undefined> => {
    try {
      if (isEmpty(payerNameField)) return [];
      const response = await searchMutation({
        accountId,
        cptCode: searchInput,
        payerName: payerNameField,
      });

      const result = (response as any).data.data.data as IProviderProcedure[];

      setFilteredProviderProceduresData(result);

      return result;
    } catch (error) {
      console.error("An error occurred when filtering:", error);
      return [];
    }
  };

  const procedures = useMemo(() => {
    return (
      filteredProviderProceduresData?.map((providerProcedure) => {
        return providerProcedure.procedure || [];
      }) || []
    );
  }, [filteredProviderProceduresData]);

  const search = async (event: { query: string }) => {
    if (event.query.trim().length) {
      const result = await fetchFilteredProviderProceduresData(
        event.query.toLowerCase()
      );
      return result;
    }
    return [];
  };

  return {
    loadedProviderProcedure: !isLoading,
    procedures,
    providerProcedures: filteredProviderProceduresData || [],
    search,
  };
}
