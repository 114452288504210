import dayjs from "dayjs";

export function validateNextInstallmentDate(
  input: string,
  blockDays: "past" | "future"
) {
  const pattern = /^\d{2}\/\d{2}\/\d{4}$/;

  if (pattern.test(input)) {
    const parts = input.split("/");
    const month = parseInt(parts[0], 10);
    const day = parseInt(parts[1], 10);
    const year = parseInt(parts[2], 10);

    const tomorrow = dayjs.utc().add(1, "day").startOf("day");
    const selectedDate = dayjs(input).utc().startOf("day");

    if (
      month >= 1 &&
      month <= 12 &&
      day >= 1 &&
      day <= 31 &&
      year >= 1000 &&
      year <= 9999
    ) {
      if (blockDays === "future") {
        if (selectedDate.isSameOrAfter(tomorrow, "date")) return true;
      } else if (selectedDate.isSameOrBefore(tomorrow, "date")) return true;
    }
  }

  return "Invalid Date";
}
