import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import styled from "styled-components";

const NoteToPdfContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 330px;
`;

type NoteToPdfDialogProps = {
  showDialog: boolean;
  setShowDialog: (show: boolean) => void;
  onSaveNote: (note: string) => void;
  note: string;
  setNote: (note: string) => void;
};

export function NoteToPdfDialog({
  showDialog,
  setShowDialog,
  onSaveNote,
  note,
  setNote,
}: NoteToPdfDialogProps) {
  const footerComponent = () => (
    <div>
      <Button
        label="Cancel"
        onClick={() => {
          setNote("");
          setShowDialog(false);
        }}
        className="p-button-link mr-2"
      />
      <Button
        label="Save"
        onClick={() => {
          onSaveNote(note);
          setNote("");
          setShowDialog(false);
        }}
      />
    </div>
  );

  return (
    <Dialog
      onHide={() => {
        setNote("");
        setShowDialog(false);
      }}
      visible={showDialog}
      header="Add Note for PDF"
      footer={footerComponent}
    >
      <NoteToPdfContainer>
        <span>Please, add your note here!</span>
        <InputTextarea
          value={note}
          onChange={(e) => setNote(e.target.value)}
          rows={2}
          placeholder="Notes"
          className="w-full mt-3"
        />
      </NoteToPdfContainer>
    </Dialog>
  );
}
