import { Label } from "layout/typography/Label";
import { ToggleButton } from "primereact/togglebutton";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { IPDFContent } from "types/Customize";
import { OrderType } from "types/Order/OrderType";

import { StyledCard } from "../styled";

interface IPDFContentPageFields {
  "GFE-noDiscountPdf"?: boolean;
  "GFE-addNoteToPdf"?: boolean;
  "Bundled-noDiscountPdf"?: boolean;
  "Bundled-addNoteToPdf"?: boolean;
  "Bariatrics-noDiscountPdf"?: boolean;
  "Bariatrics-addNoteToPdf"?: boolean;
  "ED-noDiscountPdf"?: boolean;
  "ED-addNoteToPdf"?: boolean;
  "PatientResponsibility-noDiscountPdf"?: boolean;
  "PatientResponsibility-addNoteToPdf"?: boolean;
}

export function PDFContentForm({
  customFields,
  orderType,
}: {
  customFields?: IPDFContent;
  orderType: OrderType;
}) {
  const {
    control,
    trigger,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext<IPDFContentPageFields>();

  const initializeFields = () => {
    setValue(
      `${orderType}-noDiscountPdf`,
      customFields?.noDiscountPdf || false
    );
    setValue(`${orderType}-addNoteToPdf`, customFields?.addNoteToPdf || false);
  };

  useEffect(() => {
    initializeFields();
  }, [customFields, orderType]);

  return (
    <StyledCard data-test-id="customize-pdf-content" title="Order PDF Content">
      <div className="flex flex-column gap-4 w-100">
        <div className="formgrid grid w-100">
          <div className="field flex flex-column col-12">
            <Label
              htmlFor={`${orderType}-noDiscountPdf`}
              data-test-id={`${orderType}-noDiscountPdf`}
            >
              No-Discount PDF
            </Label>

            <Controller
              data-test-id={`${orderType}-noDiscountPdf`}
              name={`${orderType}-noDiscountPdf`}
              control={control}
              render={({ field: { onChange, value } }) => (
                <ToggleButton
                  id={`${orderType}-noDiscountPdf`}
                  checked={value}
                  onChange={onChange}
                  className="w-8rem"
                />
              )}
            />
          </div>
          <div className="field flex flex-column col-12">
            <Label
              htmlFor={`${orderType}-addNoteToPdf`}
              data-test-id={`${orderType}-addNoteToPdf`}
            >
              Add Note to PDF
            </Label>

            <Controller
              data-test-id={`${orderType}-addNoteToPdf`}
              name={`${orderType}-addNoteToPdf`}
              control={control}
              render={({ field: { onChange, value } }) => (
                <ToggleButton
                  id={`${orderType}-addNoteToPdf`}
                  checked={value}
                  onChange={onChange}
                  className="w-8rem"
                />
              )}
            />
          </div>
        </div>
      </div>
    </StyledCard>
  );
}
