import { ToastContext } from "context/ToastContext";
import { sizer } from "layout/styles/styled/sizer";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { useContext, useState } from "react";
import { useCreateMoneyTransfersMutation } from "store/queries/moneyTransfers";
import styled from "styled-components";
import { ITransfer } from "types/MoneyTransfers";
import { IOrder } from "types/Order/Order";

import { SingleTransferForm } from "./components/SingleTransferForm";
import { mapFormToRequestBody } from "./utils/helpers";

const StyledDialog = styled(Dialog)`
  .p-dialog-title {
    color: #5b4dfd;
  }
`;

export const StyledButton = styled(Button)`
  gap: ${sizer(2)};
  margin-bottom: ${sizer(4)};

  span:first-child {
    font-size: ${sizer(3)};
  }
`;

export function MoneyTransferDialog({
  showDialog,
  setShowDialog,
  order,
  transfers,
  setTransfers,
  onUpdateOrder,
}: {
  showDialog: boolean;
  setShowDialog: any;
  order: IOrder;
  transfers: ITransfer[];
  setTransfers: any;
  onUpdateOrder: () => void;
}) {
  const [notes, setNotes] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const { current: toastElement } = useContext(ToastContext);
  const [useCreateMoneyTransfers] = useCreateMoneyTransfersMutation();
  const handleCreateMoneyTransfers = async () => {
    const requestBody = mapFormToRequestBody({
      orderId: order.id,
      moneyTransfers: transfers,
      note: notes?.trim()?.length ? notes.trim() : undefined,
    });
    setLoading(true);
    useCreateMoneyTransfers(requestBody)
      .unwrap()
      .then(async () => {
        toastElement?.show({
          severity: "success",
          summary: "Money Transfers created",
          detail: "Money Transfers created successfully",
        });
        setLoading(false);
        setShowDialog(false);
        setNotes("");
        onUpdateOrder();
      })
      .catch((error) => {
        toastElement?.show({
          severity: "error",
          summary: "Error",
          detail: error.data.messages[0],
        });
        setLoading(false);
      });
  };

  const footerContent = (
    <div className="flex justify-content-center">
      <Button
        label="Transfer"
        onClick={handleCreateMoneyTransfers}
        autoFocus
        loading={loading}
      />
    </div>
  );

  const handleAddAnotherTransfer = () => {
    setTransfers((prev: any) => [
      ...prev,
      {
        selectedPartyToTransferFrom: null,
        selectedPartyToTransferTo: null,
        selectedAmountToTransfer: 0,
      },
    ]);
  };

  const handleRemoveTransfer = (index: number) => {
    const transferCopy = [...transfers];
    transferCopy.splice(index, 1);
    setTransfers(transferCopy);
  };

  return (
    <StyledDialog
      header="Partial Refund"
      visible={showDialog}
      style={{ width: "40vw" }}
      onHide={() => {
        setShowDialog(false);
        setTransfers([
          {
            selectedPartyToTransferFrom: null,
            selectedPartyToTransferTo: null,
            selectedAmountToTransfer: 0,
          },
        ]);
      }}
      footer={footerContent}
    >
      <div className="flex flex-column">
        {transfers.map((transfer, i) => (
          <SingleTransferForm
            key={`${i}_order_transfer`}
            transfer={transfer}
            setTransfers={setTransfers}
            order={order}
            index={i}
            handleRemoveTransfer={handleRemoveTransfer}
            showRemoveButton={transfers.length > 1}
          />
        ))}
        <StyledButton
          data-testid="remove-transfer"
          icon="pi pi-plus-circle"
          className="p-button-text p-button-primary p-0 mb-0"
          style={{ alignSelf: "end" }}
          onClick={handleAddAnotherTransfer}
        >
          <p className="small-text">Add Another</p>
        </StyledButton>
        <InputTextarea
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          rows={2}
          placeholder="Notes"
          className="w-full mt-3"
        />
      </div>
    </StyledDialog>
  );
}
