export function Payment({ ...rest }) {
  return (
    <svg
      width="512"
      height="512"
      viewBox="0 0 512 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0_427_25)">
        <path
          d="M488.884 307.418C463.699 298.302 435.742 300.407 412.174 313.195C411.867 313.347 319.901 354.91 319.901 354.91C313.367 359.804 305.789 362.389 297.985 362.389H240.122C232.934 362.389 227.107 356.569 227.107 349.39C227.107 342.211 232.934 336.39 240.122 336.39H297.985C316.073 336.39 316.469 299.177 298.752 297.703L218.103 296.776C203.872 296.619 189.629 287.471 174.628 287.128C159.36 286.779 144.916 290.673 132.08 298.998C114.206 310.591 102.003 329.015 93.8242 348.332L137.458 458.519H262.921C274.244 458.519 285.426 455.548 295.256 449.924L489.965 338.538C495.884 333.519 500.457 327.657 498.449 318.655C497.317 313.594 493.685 309.158 488.884 307.418Z"
          fill="#5A5EAA"
        />
        <path
          d="M13.0723 361.833L72.5365 512L120.267 485.791L60.802 335.625L13.0723 361.833Z"
          fill="#5A5EAA"
        />
        <path
          d="M371.401 0C307.558 0 255.805 52.4548 255.805 117.16C255.805 181.866 307.559 234.321 371.401 234.321C435.242 234.321 486.997 181.866 486.997 117.16C486.998 52.4548 435.243 0 371.401 0ZM384.537 175.643V195.065H357.788V177.223C347.454 175.687 338.27 171.575 330.337 165.341L339.929 138.438C347.709 144.55 358.824 150.135 368.381 150.135C375.711 150.135 380.448 146.771 380.448 141.564C380.448 138.179 379.36 134.123 366.365 128.827C350.099 122.408 333.447 112.993 333.447 91.9051C333.447 75.9839 343.178 63.1926 358.972 58.1492V39.2544H385.555V56.4743C393.643 57.7368 402.295 61.6807 409.412 66.2723L399.441 92.7508C392.275 87.971 382.111 83.3324 373.956 83.3324C365.041 83.3324 364.078 87.901 364.078 89.8648C364.078 92.94 365.226 95.5941 380.07 101.77C394.585 107.725 411.245 117.493 411.245 140.372C411.246 156.718 400.822 170.389 384.537 175.643Z"
          fill="#59C2B1"
        />
      </g>
      <defs>
        <clipPath id="clip0_427_25">
          <rect width="512" height="512" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
