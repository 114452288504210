import { createSlice } from "@reduxjs/toolkit";
import { Steps } from "pages/OrderOverview/utils/constants";
import { Steps as OrderUpdateSteps } from "pages/OrderUpdate/utils/constants";
import type { RootState } from "store";
import { IAccount } from "types/Account/Account";
import { IIntegrationError, IOrder } from "types/Order/Order";

interface IOrderStateProps {
  viewOrderViewReceipt: boolean;
  overviewStep: Steps;
  orderUpdateStep: OrderUpdateSteps;
  accountInfo: IAccount;
  payersNameInfo: {
    name: string;
    value: string;
  }[];
  selectedDraftOrder: IOrder;
  selectedOrderToRedo: IOrder;
  integrationErrors: IIntegrationError[];
  stripeClientSecret: string;
}

const initialState: IOrderStateProps = {
  viewOrderViewReceipt: false,
  overviewStep: Steps.DOB_AUTH,
  orderUpdateStep: OrderUpdateSteps.DOB_AUTH,
  accountInfo: {} as IAccount,
  payersNameInfo: [],
  selectedDraftOrder: {} as IOrder,
  selectedOrderToRedo: {} as IOrder,
  integrationErrors: [],
  stripeClientSecret: "",
};

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    onChangeViewOrderViewReceipt: (state, action: { payload: boolean }) => {
      state.viewOrderViewReceipt = action.payload;
    },
    onOrderOverviewStepChange: (
      state,
      action: { payload: { step: Steps } }
    ) => {
      state.overviewStep = action.payload.step;
    },
    onOrderUpdateStepChange: (
      state,
      action: { payload: { step: OrderUpdateSteps } }
    ) => {
      state.orderUpdateStep = action.payload.step;
    },
    onChangeAccountInfo: (
      state,
      action: { payload: { accountInfo: IAccount } }
    ) => {
      state.accountInfo = action.payload.accountInfo;
    },
    onPayerNameLoad: (state, action) => {
      state.payersNameInfo = action.payload;
    },
    onSelectDraftOrder: (state, action: { payload: IOrder }) => {
      state.selectedDraftOrder = action.payload;

      state.integrationErrors = Object.entries(
        action.payload.integrationErrors ?? {}
      ).map((aError) => {
        return {
          errorType: aError[0],
          message: aError[1],
          active: true,
        };
      });
    },
    onSelectOrderToRedo: (state, action: { payload: IOrder }) => {
      state.selectedOrderToRedo = action.payload;
    },
    onIntegrationChange: (
      state,
      action: {
        payload: IIntegrationError;
      }
    ) => {
      const remainingErrors = state.integrationErrors.filter((aError) => {
        return aError.errorType !== action.payload.errorType;
      });
      state.integrationErrors = [...remainingErrors, action.payload];
    },
    onStripeClientSecretLoad: (state, action) => {
      state.stripeClientSecret = action.payload;
    },
  },
});

export const {
  onOrderOverviewStepChange,
  onOrderUpdateStepChange,
  onChangeAccountInfo,
  onChangeViewOrderViewReceipt,
  onPayerNameLoad,
  onSelectDraftOrder,
  onSelectOrderToRedo,
  onIntegrationChange,
  onStripeClientSecretLoad,
} = orderSlice.actions;

export const orderSelector = (state: RootState) => state.orderSlice;

export default orderSlice.reducer;
