import { ItemBar } from "components/ItemBar";
import { sizer } from "layout/styles/styled/sizer";
import { Button } from "primereact/button";
import styled from "styled-components";

const StyledH2 = styled.h2`
  margin-bottom: ${sizer(8)};
`;

export function RedoHeader({
  accountName,
  handleSwitchRedoOrder,
}: {
  accountName: string;
  handleSwitchRedoOrder: () => void;
}) {
  return (
    <ItemBar>
      <StyledH2>{accountName}</StyledH2>

      <div data-testid="header" className="flex flex-1 justify-content-end">
        <Button label="Cancel" onClick={handleSwitchRedoOrder} />
      </div>
    </ItemBar>
  );
}
