import { isEmpty } from "lodash";
import {
  IMonthlyReportResponse,
  IBaseMonthlyReportResponse,
  IDefaultMonthlyReportResponse,
} from "types/Report/Report";

export const convertDataToCSV = (
  orderType: "patientResponsibility" | "clearPackage",
  data: IMonthlyReportResponse | undefined
) => {
  if (isEmpty(data)) return undefined;

  if (orderType === "patientResponsibility") {
    let csvString =
      '"hospital_name","order_id","date_of_service","account_number","total_estimate","facility_fee","patient_price","status","order_type","order_attempt_number","created_at","updated_at","code","cpt_description","notes"\n';
    data.patientResponsibility.forEach((row: IBaseMonthlyReportResponse) => {
      csvString += `"${row.hospitalName}","${row.orderId}","${row.dateOfService}","${row.accountNumber}","${row.totalEstimate}","${row.facilityFee}","${row.patientPrice}","${row.status}","${row.orderType}","${row.orderAttemptNumber}","${row.createdAt}","${row.updatedAt}","${row.code}","${row.cptDescription}","${row.notes}"\n`;
    });
    return csvString;
  }
  if (orderType === "clearPackage") {
    let csvString =
      '"hospital_name","provider_name","specialty","order_id","date_of_service","account_number","patient_price","status","order_attempt_number","created_at","updated_at","code","cpt_description","provider_price","out_of_pocket_reason","estimated_visit_cost","notes"\n';
    data.clearPackage.forEach((row: IDefaultMonthlyReportResponse) => {
      csvString += `"${row.hospitalName}","${row.providerName}","${
        row.specialty
      }","${row.orderId}","${row.dateOfService}","${row.accountNumber}","${
        row.patientPrice
      }","${row.status}","${row.orderAttemptNumber}","${row.createdAt}","${
        row.updatedAt
      }","${row.code}","${row.cptDescription}","${
        row.providerAmountInDollars
      }","${row.outOfPocketReason ?? ""}","${
        row.estimatedVisitCostInDollars
      }","${row.notes}"\n`;
    });
    return csvString;
  }
  return undefined;
};
