import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import styled from "styled-components";

const CancelOrderContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export function CancelOrderDialog({
  showCancelDialog,
  setShowCancelDialog,
  cancelNotes,
  setCancelNotes,
  onCancelOrder,
}: {
  showCancelDialog: boolean;
  setShowCancelDialog: any;
  cancelNotes: string;
  setCancelNotes: any;
  onCancelOrder: any;
}) {
  const footerComponent = () => {
    return (
      <div>
        <Button
          label="No"
          onClick={() => {
            setCancelNotes("");
            setShowCancelDialog(false);
          }}
          className="p-button-link mr-2"
        />
        <Button
          label="Yes"
          onClick={() => {
            onCancelOrder();
          }}
        />
      </div>
    );
  };
  return (
    <Dialog
      onHide={() => {
        setCancelNotes("");
        setShowCancelDialog(false);
      }}
      visible={showCancelDialog}
      header="Confirmation"
      footer={footerComponent}
    >
      <div className="flex gap-2">
        <i
          className="pi pi-exclamation-triangle"
          style={{ fontSize: "28px" }}
        />
        <CancelOrderContainer>
          <span>Are you sure you want to cancel this order?</span>
          <InputTextarea
            value={cancelNotes}
            onChange={(e) => setCancelNotes(e.target.value)}
            rows={2}
            placeholder="Notes"
            className="w-full mt-3"
          />
        </CancelOrderContainer>
      </div>
    </Dialog>
  );
}
