import { mapPayerNameToCodeForDropdown } from "constants/practiceType";
import { useUserWithAccount } from "context/UserAccountProvider";
import useDocumentTitle from "hooks/useDocumentTitle";
import { usePayerInformation } from "hooks/usePayerInformation";
import { useQuery } from "hooks/useQuery";
import { isEmpty } from "lodash";
import { useEffect, useMemo } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useGetAccountByIdQuery } from "store/queries/account";

import {
  Header,
  VisitForm,
  Submit,
  PatientInfoForm,
  OrderType,
  ServiceDetails,
  PayerForm,
  PatientBenefitsForm,
  Communication,
} from "./components";
import { TypesCode, orderTypes } from "./components/Header/constants";
import { PaymentPlan } from "./components/PaymentPlan/PaymentPlan";

export function CreateOrder() {
  const query = useQuery();
  useDocumentTitle("Create Order");
  const accountId = query.get("accountId") || "";
  const { accounts, loadingAccounts } = useUserWithAccount();
  const account = useMemo(() => {
    return accounts?.data.find((acc) => acc.id === accountId);
  }, [accounts]);

  const { data: accountData } = useGetAccountByIdQuery(accountId, {
    skip: isEmpty(accountId),
  });

  usePayerInformation();

  const methods = useForm({
    defaultValues: {
      email: null,
      amount: null,
      lastName: null,
      firstName: null,
      dateOfBirth: null,
      totalAmount: null,
      phoneNumber: null,
      accountNumber: null,
      dateOfService: null,
      timeOfService: null,
      serviceDetails: null,
      outOfPocketReason: null,
      preferredContactMethod: null,
      estimatedVisitCostForPatient: null,
      orderType: orderTypes[TypesCode.Bundled].value,
      accountId,
      payerName: null,
      placeOfService: null,
      locality: null,
      deductible: null,
      outOfPocketMax: null,
      flatCopay: null,
      coinsurancePercent: null,
      gfeQuoteId: null,
      practiceType: mapPayerNameToCodeForDropdown[0]?.value,
      orderId: null,
      paymentPlanDuration: null,
      paymentPlanMinDownPaymentPercent: null,
      paymentPlanEnabled: true,
      eligibilityDetails: null,
      paymentPlanMinDownPaymentFlat: null,
      paymentPlanMinDownPaymentType: null,
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <FormProvider {...methods}>
      <Header />
      {account && (
        <OrderType
          orderTypesVisible={account.orderTypesVisible}
          isFetching={loadingAccounts}
        />
      )}
      <PatientInfoForm />
      <PayerForm />
      <PatientBenefitsForm />
      <ServiceDetails />
      {!!accountData?.data && <PaymentPlan accountData={accountData} />}
      <VisitForm />
      {account && <Communication account={account} />}
      <Submit />
    </FormProvider>
  );
}
