import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { LoadingSpinner } from "components/LoadingSpinner";
import { ISPAConfig } from "config/api";
import { PaymentSection } from "pages/OrderOverview/components/PaymentSection";
import { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { useCreateOrderPaymentIntentMutation } from "store/queries/order";

const getPublishableKey = () => {
  const config = window?.config as ISPAConfig;

  if (!config) {
    throw new Error("Config not found");
  }

  return config.stripe.publishableKey;
};

export default function PaymentPlan({
  expirationDays,
  orderInfo,
  showPaymentSection,
  setShowPaymentSection,
  clearPrice,
  totalSaving,
  fullPrice,
}: any) {
  const { id: externalId } = useParams();

  const [stripePromise, setStripePromise] =
    useState<Promise<Stripe | null> | null>(null);

  useEffect(() => {
    setStripePromise(loadStripe(getPublishableKey()));
  }, []);

  const [
    createOrderPaymentIntent,
    { isError: isStripeError, isLoading: isStripeLoading },
  ] = useCreateOrderPaymentIntentMutation();

  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    createOrderPaymentIntent({
      orderId: externalId || "",
      paymentPlan: true,
      payRemainingPrice: false,
    })
      .unwrap()
      .then(({ data }: { data: any }) => setClientSecret(data.client_secret));
  }, []);

  if (isStripeError) {
    return <Navigate to="/not-found" />;
  }

  if (isStripeLoading || !clientSecret) {
    return <LoadingSpinner />;
  }

  return (
    <Elements
      stripe={stripePromise}
      options={{
        clientSecret,
        // HACK TO HIDE THE TERMS TEXT IN STRIPE EMBEDDED ELEMENT
        appearance: {
          rules: {
            ".TermsText": {
              color: "transparent",
              fontSize: "0px",
            },
          },
        },
      }}
    >
      <PaymentSection
        expirationDays={expirationDays}
        order={orderInfo}
        prices={{ clearPrice, totalSaving, fullPrice }}
        onReturnClick={() => {
          setShowPaymentSection({
            paymentPlan: false,
            paymentSection: false,
          });
        }}
        showPaymentSection={showPaymentSection}
      />
    </Elements>
  );
}
