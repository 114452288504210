export const tooltipTexts = {
  conversionRate:
    "Calculated as (paid orders + partially paid orders) divided by (total orders - sent orders - draft orders - canceled before payment - no balance due).",
  averageDaysToCollect:
    "The average number of days between the creation date and payment date for paid orders.",
  totalRevenue:
    "The total collections from payments, including provider fees, facility charges, and clear fees.",
  paid: "Includes both partially paid and fully paid orders.",
  estimatesCreated:
    "The total number of orders generated, excluding those canceled before payment, and includes orders with no balance due.",
};
